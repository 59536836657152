@use '../../styles/settings/gutters';
@use '../../styles/settings/fonts';
@use '../../styles/settings/colors';
@use '../../styles/settings/media';

.course {
    flex: 0 1 50%;
    margin: 0 gutters.$gutter;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: gutters.$gutter-medium;

    @include media.bp(sm) {
        margin-bottom: 0;
    }

    .imageBox {
        background: colors.$color-white;
        font-size: 0;
        filter: drop-shadow(0px 4px 9px rgba(0, 23, 115, 0.5));
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: gutters.$gutter-medium;
        width: 100%;

        > div {
            width: 100%;
        }
    }

    .image {
        width: 100%;
        font-size: 0;
    }

    .title {
        font-family: fonts.$font-headline;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: -0.01em;
        color: colors.$color-white;
        margin-bottom: gutters.$gutter-small;
        margin-top: 0;
    }

    .text {
        color: colors.$color-white;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.01em;
        margin-bottom: gutters.$gutter-medium;
    }
}