@use '../../styles/settings/gutters';
@use '../../styles/settings/colors';
@use '../../styles/settings/fonts';
@use '../../styles/settings/media';

.listenNow {
    background: linear-gradient(90.1deg, colors.$color-primary-500 0.08%, colors.$color-primary-300 99.21%);
    border-radius: 8px;
    filter: drop-shadow(0px 12px 16px rgba(0, 23, 115, 0.5));
    padding: 40px 45px;
    margin: 0 -40px;
    width: calc(100% + 80px);

    &.isIdle {
        padding: 20px 25px;
        background: linear-gradient(90.1deg, colors.$color-primary-900 0.08%, colors.$color-primary-300 99.21%);
    }

    .title {
        margin: 0 0 gutters.$gutter-medium;
        color: colors.$color-white;
        font-size: 24px;
        line-height: 1.2;
        font-family: fonts.$font-headline;
    }

    .podcasts {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 (-(gutters.$gutter));

        @include media.bp(sm) {
            flex-direction: row;
        }

        @include media.bp(lg) {
            flex-wrap: nowrap;
        }
    }

    .podcastLink {
        display: flex;
        margin: gutters.$gutter;
        flex: 1 1 calc(50% - 32px);

        @include media.bp(lg) {
            flex: 1 1 calc(25% - 16px);
            margin: 0 gutters.$gutter;
            max-width: calc(25% - 16px);
        }
    }

    .podcast {
        background: colors.$color-black;
        box-shadow: 0px 4px 9px rgba(0, 23, 115, 0.4);
        border-radius: 4px;
        padding: gutters.$gutter-medium gutters.$gutter-small;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}
