@use '../styles/settings/gutters';
@use '../styles/settings/media';

.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.podcastSection {
  position: relative;

  .listenNow {
    @include media.bp(lg) {
      position: absolute;
      top: calc(100% + 70px);
    }
  }
}

.coursesSection {
  background: url("../public/courses-bg.svg") no-repeat;
  background-position: top center;
  background-size: cover;
  padding-top: gutters.$gutter-bigger;
  padding-bottom: gutters.$gutter-bigger;

  @include media.bp(lg) {
    padding-top: gutters.$gutter-medium * 7;
  }
}

.booksSection {
  margin-bottom: 90px;
  min-height: 700px;
  padding-top: gutters.$gutter-big + gutters.$gutter-small;
  position: relative;

  &::before {
    background: url("../public/books-bg.svg") no-repeat;
    background-position: top center;
    background-size: cover;
    content: "";
    display: block;
    width: 100%;
    height: 525px;
    position: absolute;
    top: 0;
    z-index: -1;
  }
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 3rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}