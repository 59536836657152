@use '../../styles/settings/gutters';
@use '../../styles/settings/media';

.section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.wrapper {
    display: flex;
    margin: 0 -(gutters.$gutter);
    flex-direction: column;
    margin-bottom: gutters.$gutter-big;

    @include media.bp(sm) {
        flex-direction: row;
    }
}