@use '../../styles/settings/colors';
@use '../../styles/settings/gutters';
@use '../../styles/settings/media';

.hero {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: gutters.$gutter-biggest + gutters.$gutter;
    flex-direction: column;

    @include media.bp(md) {
        flex-direction: row;
    }

    .contentWrapper {
        flex: 0 1 auto;

        @include media.bp(sm) {
            padding-right: gutters.$gutter * 2;
        }
    }

    .imageWrapper {
        display: none;

        @include media.bp(md) {
            display: block;
            flex: 1 0 430px;
        }
    }

    .image {
        width: 100%;
        object-fit: contain;

        @include media.bp(sm) {
            width: auto;
        }
    }

    .title {
        text-transform: initial;
        margin-top: gutters.$gutter-big;
        margin-bottom: gutters.$gutter;
    }

    .text {
        color: colors.$color-primary-700;
        font-size: 16px;
        letter-spacing: -0.01em;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: gutters.$gutter-big;

        @include media.bp(sm) {
            font-size: 18px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
        @include media.bp(sm) {
            flex-direction: row;
        }
    }

    .secondButton {
        padding: gutters.$gutter;
        margin-left: gutters.$gutter;
    }
}