@use '../../styles/settings/gutters';
@use '../../styles/settings/colors';
@use '../../styles/settings/fonts';
@use '../../styles/settings/media';

.lastArticlesWrapper {
    margin-bottom: gutters.$gutter * 8;
    position: relative;
}

.lastArticlesSection {
    border-bottom: 4px solid colors.$color-border;
    margin-bottom: gutters.$gutter + gutters.$gutter-medium;
    position: relative;

    &::after {
        content: "";
        height: 4px;
        width: 270px;
        position: absolute;
        display: block;
        background: colors.$color-border-darker;
        border-radius: 4px;
    }
}

.lastArticles {
    display: flex;
    padding-bottom: gutters.$gutter-medium + gutters.$gutter;
    position: relative;
    transition: 700ms;
    gap: (gutters.$gutter * 2);
}

.lastBox {
    flex: 0 1 352px;
    margin: 0 gutters.$gutter;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 700ms;
    text-align: center;
    background: radial-gradient(77.73% 1243.75% at 50% 50%, rgba(222, 243, 255, 0.85) 0%, rgba(222, 243, 255, 0) 100%);
    filter: drop-shadow(0px 4px 8px rgba(20, 165, 255, 0.24));
    border-radius: 8px;
    border: 1px solid rgba(colors.$color-primary-300, 40%);
    padding: gutters.$gutter;
}

.lastIco {
    margin: 0 0 gutters.$gutter-medium;
    font-size: 90px;
}

.lastTitle {
    color: colors.$color-primary;
    font-family: fonts.$font-headline;
    font-size: 32px;
    line-height: 1.25;
    margin: 0 0 gutters.$gutter;
    letter-spacing: -0.01em;
}

.lastMsg {
    color: colors.$color-primary-700;
    font-size: 14px;
    line-height: 1.4;
    margin: 0 0 gutters.$gutter-medium;
    letter-spacing: -0.01em
}

.btnsLine {
    display: flex;
    
    flex-direction: column-reverse;

    @include media.bp(sm) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}


.sliderBtns {
    display: flex;
    margin-bottom: gutters.$gutter-big;

    @include media.bp(sm) {
        margin-bottom: 0;
    }
}

.previous {
    transform: scaleX(-1);
    margin-right: gutters.$gutter-small;
}

.inactive {
    pointer-events: none;
    opacity: 0.5;
}

@keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.inactivePreview {
    animation: hide 700ms 300ms forwards;
}
