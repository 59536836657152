@use '../../styles/settings/gutters';
@use '../../styles/settings/media';

.section {
    margin-bottom: gutters.$gutter-big;

    @include media.bp(lg) {
        margin-bottom: 185px;
    }

    .btnsLine {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;

        @include media.bp(md) {
            flex-direction: row;
            align-items: center;
        }

        a {
            margin-right: gutters.$gutter-big;
            margin-bottom: gutters.$gutter-medium;

            @include media.bp(md) {
                margin-bottom: 0;
            } 
        }
    }
}

.wrapper {
    margin-bottom: gutters.$gutter * 2;
    display: flex;
    flex-direction: column;

    @include media.bp(md) {
        flex-direction: row;
    }

    .main {
        flex: 0 1 100%;
        margin-bottom: gutters.$gutter;

        @include media.bp(sm) {
            flex: 0 1 50%;
        }

        @include media.bp(md) {
            margin-right: gutters.$gutter;
            margin-bottom: 0;
        }

        @include media.bp(lg) {
            margin-right: gutters.$gutter * 2;
            flex: 0 1 736px;
        }
    }

    .column {
        flex: auto;
        display: flex;
        flex-direction: column;
    }
}