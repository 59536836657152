@use '../../styles/settings/gutters';
@use '../../styles/settings/colors';
@use '../../styles/settings/fonts';

.episode {
    background: linear-gradient(90.1deg, colors.$color-primary-500 0.08%, colors.$color-primary-300 99.21%);
    box-shadow: 0px 4px 8px rgba(20, 165, 255, 0.24);
    border-radius: 4px;
    border-top: 6px solid colors.$color-primary-300;
    font-family: fonts.$font-headline;
    line-height: 1.2;
    letter-spacing: -0.01em;
    text-shadow: 0px 4px 9px rgba(0, 23, 115, 0.5);
    color: colors.$color-white;

    &.big {
        font-size: 36px;
        padding: gutters.$gutter 18px 18px;
    }

    &.small {
        font-size: 24px;
        padding: 14px gutters.$gutter gutters.$gutter;
    }
}