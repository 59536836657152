@use '../../styles/settings/gutters';
@use '../../styles/settings/colors';
@use '../../styles/settings/fonts';
@use '../../styles/settings/media';

.lastPodcastPreview {
    position: relative;
    min-height: 605px;
    border-radius: 4px;

    &.small {
        min-height: 191px;
        margin-bottom: gutters.$gutter;
        overflow: hidden;

        .episode {
            top: 0;
            left: gutters.$gutter;
        }

        .content {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.57) 0%, #DEF3FF 100%);
            left: 0;
            bottom: 0;
            max-width: 100%;
            width: 100%;
            padding: gutters.$gutter;
        }

        .title {
            font-size: 16px;
            margin-bottom: 0;
        }
    }

    .imageWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        > div {
            width: 100%;

            img {
                object-fit: cover;
            }
        }

        @include media.bp(lg) {
            width: auto;

            > div {
                width: auto;
    
                img {
                    object-fit: initial;
                }
            }
        }
    }

    .image {
        border-radius: 4px;
    }

    .episode {
        position: absolute;
        top: gutters.$gutter-medium;
        left: gutters.$gutter-medium;
        z-index: 1;
    }

    .content {
        background: linear-gradient(180deg, rgba(222, 243, 255, 0.85) 0%, rgba(255, 255, 255, 0.68) 100%);
        backdrop-filter: blur(39px);
        border-radius: 4px;
        position: absolute;
        bottom: gutters.$gutter-medium;
        left: 0;
        padding: (gutters.$gutter-medium + gutters.$gutter-small) (gutters.$gutter-bigger) (gutters.$gutter-medium + gutters.$gutter-small) (gutters.$gutter-medium + gutters.$gutter-small);
        max-width: 545px;

        @include media.bp(sm) {
            left: gutters.$gutter-medium;
        }

        @include media.bp(md) {
            left: 0;
        }

        @include media.bp(lg) {
            left: gutters.$gutter-medium;
        }
    }

    .date {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.01em;
        color: colors.$color-text;
        line-height: 1.4;
        margin: 0 0 (gutters.$gutter-small * 1.5);
    }

    .title {
        position: relative;
        color: colors.$color-primary;
        font-size: 24px;
        line-height: 1.25;
        font-family: fonts.$font-headline;
        letter-spacing: -0.01em;
        margin: 0 0 gutters.$gutter-small;
    }

    .text {
        color: colors.$color-black;
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: -0.01em;
        margin: 0;
    }
}
